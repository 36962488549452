<template>
  <div style="display: flex; justify-content: space-between;" class="articleCon">
    <div class="article-content" v-if="id">
      <p style="font-size: 36px; font-weight: 600" id="title">{{ article.name }}</p>
      <!--    文章内容展示-->
      <div>
        <div v-html="article.content" @click="getImgPreview($event)"></div>
        <el-image-viewer v-if="showViewer" @close="closeViewer" :hide-on-click-modal="true" :url-list="[url]" />
      </div>
      <span class="time HelpText">最后更新于：{{ article.update_at }}</span>

      <section class="relation">
        <h2>相关文章</h2>
        <ul>
          <li v-for="art in relationList" :key="art.id">
            <router-link :to="`/help/${art.id}?type=1`">{{ art.name }}</router-link>
          </li>
        </ul>
      </section>
    </div>
    <!--  没有文章时占位展示-->
    <el-empty v-else description="暂无内容"></el-empty>

    <div class="affix-box">
      <div class="affix-pointer">
        <!-- 用户反馈 -->
        <div class="help-block SubHead">
          评价此篇文档<span class="HelpText" v-show="crmId > 0">（评价此文档，可获得积分+10噢！）</span>
        </div>
        <div class="feedback-box" v-if="isSubmit">
          <div class="btns-box">
            <el-button type="info" plain icon="el-icon-success" @click="helpfulBtn(1)">有帮助</el-button>
            <el-button type="info" plain icon="el-icon-error" @click="unhelpfulBtn(2)">无帮助</el-button>
          </div>
          <el-form ref="feedbackFormRef" :model="feedbackForm" :rules="feedbackFormRules">
            <!-- 有帮助表单部分 -->
            <div v-show="ishelpful">
              <p class="Text">（选填）请问还有其它建议吗？</p>
              <el-form-item label="">
                <el-input type="textarea" v-model="feedbackForm.content"></el-input>
              </el-form-item>
              <el-form-item style="text-align: right">
                <el-button size="small" @click="cancelFormSubmit">暂时没有</el-button>
                <el-button type="primary" size="small" @click="helpfulSubmit">提交</el-button>
              </el-form-item>
            </div>

            <!-- 无帮助表单部分 -->
            <div v-show="isUnhelpful">
              <el-scrollbar height="350px">
                <el-form-item label="请问出了什么问题？" prop="problem" style="display: block">
                  <el-checkbox-group v-model="feedbackForm.problem" @change="checkedProblemsChange">
                    <el-checkbox v-for="p in problems" :label="p" :key="p">{{ p }}</el-checkbox>
                  </el-checkbox-group>
                  <el-checkbox v-model="uncheckAll" label="以上都不是" @change="checkedAllChange"></el-checkbox>
                </el-form-item>
                <el-form-item label="请留下具体问题或建议" prop="content" style="display: block">
                  <el-input type="textarea" v-model="feedbackForm.content"></el-input>
                </el-form-item>
              </el-scrollbar>
              <el-form-item style="text-align: right">
                <el-button size="small" @click="cancelFormSubmit">取消</el-button>
                <el-button type="primary" size="small" @click="UnhelpfulSubmit">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <p v-else class="submitTip"><i class="el-icon-circle-check"></i> 提交成功，感谢您的反馈！</p>
        <!-- 锚点导航 -->
        <div v-if="points.length" class="anchor-box">
          <div class="help-block SubHead">本篇目录</div>
          <el-scrollbar height="300px">
            <el-row
              style="padding: 5px 0"
              v-for="(item, index) in points"
              :key="item.id"
              :class="current === index ? 'active' : ''"
              @click="getCurrent(index)"
            >
              <el-link type="info" :href="'#' + item.id" @click="getCurrent" :underline="false">
                <span v-html="item.name"></span>
                <!-- {{ item.name }} -->
              </el-link>
            </el-row>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { nextTick } from 'vue'
// import { ElImageViewer } from 'element-plus'
import base from "../../request/base";
const problemsOptions = ['找不到描述的入口/按钮', '没找到我想了解的信息', '这个功能不好用', '步骤说明不清晰/看不懂']
export default {
  name: 'Article',
  components: {
    // ElImageViewer
  },
  props: ['id'],
  data() {
    return {
      base: base,
      userInfo: '',
      token: '',
      url: '',
      showViewer: false,
      a: '',
      p: '',
      points: [],
      article: {
        id: '',
        name: '',
        content: '',
        create_at: ''
      },
      current: 0,
      isSubmit: true,
      ishelpful: false,
      isUnhelpful: false,
      uncheckAll: false,
      problems: problemsOptions,
      feedbackForm: {
        type: '',
        problem: [],
        content: ''
      },
      feedbackFormRules: {
        problem: [{ type: 'array', required: true, message: '请完成必填项', trigger: 'change' }],
        content: [{ required: true, message: '请完成必填项', trigger: 'blur' }]
      },
      relationList: [],
      crmId: ''
    }
  },
  watch: {
    id(newId) {
      this.getAndTransArticle(newId)
    },
    $route(to, from) {
      if (to.params.index !== from.params.index) {
        this.isSubmit = true
        this.ishelpful = false
        this.isUnhelpful = false
      }
    }
  },
  created() {
    this.getP(window.location.href)
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userInfo')
    if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
        this.crmId = this.userInfo.crm_id
      } else {
        this.token = ''
        this.crmId = ''
      }
    }
    this.getAndTransArticle()
    this.type = window.localStorage.getItem('type')
    this.getRelation()
  },
  methods: {
    wxShare(config) {
      // console.log(config);
      var articleName = this.article.name
      var articleId = this.article.id

      //微信分享
      wx.config(config)

      wx.ready(function() {
        const baseUrl = this.base
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: articleName + '-YinoCloud帮助中心', // 分享标题
          desc: `${baseUrl.url}/help/${articleId}?type=1`, // 分享描述
          link: `${baseUrl.url}/help/${articleId}?type=1`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${baseUrl.admin_url}/static/index/images/YinoCloud.png`, // 分享图标
          success: function() {
            // 设置成功
          }
        })
        wx.updateTimelineShareData({
          title: articleName + '-YinoCloud帮助中心', // 分享标题
          link: `${baseUrl.url}/help/${articleId}?type=1`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: `${baseUrl.admin_url}/static/index/images/YinoCloud.png`, // 分享图标
          success: function() {
            // 设置成功
          }
        })
      })
    },
    async getP(url) {
      var type = url.split('&')[2]

      if (type == undefined) {
        return
      } else {
        //下一步 获取p
        var p = type.split('=')[1]
        var p_type = type.split('=')[0]
        if (p_type == 'p') {
          this.p = p
        }
      }
    },
    getCurrent(index) {
      this.current = index
    },
    async getAndTransArticle() {
      if (this.$route.name == 'help') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
      if (!this.id) return
      var that = this

      // 记录浏览文章及时间
      setTimeout(async () => {
        if (window.localStorage.getItem('type')) {
          var token = window.localStorage.getItem('type')
        } else {
          if (this.userInfo) {
            if (this.userInfo !== 'undefined') {
              var token = this.userInfo.id
            } else {
              var token = 0
            }
            var token = 0
          }
        }

        const { data: res } = await this.$http.post('bot/page_views', {
          token: token,
          id: this.id,
          a_id: this.p
        })
        that.$bus.emit('getTask')
      }, 3000)

      //获取文章内容
      const { data: res } = await this.$http.post('bot/article', {
        id: this.id,
        token: this.token
      })
      if (res.code == 100) {
        this.$router.push('/login')
      }

      if (!res.data || !res.data.content) {
        //提示xxx
        return
      }

      //获取文章锚点及锚点id
      let result = this.transArticleContent(res.data.content)
      res.data.content = result.content
      this.points = result.points
      this.article = res.data
      this.wxShare(res.config)
      nextTick(() => {
        $('a[href^=http]').attr('target', '_blank')
        document.title = `${this.article.name}` + '-YinoCloud帮助中心'
        // console.log(this.article.content)
        var reg = /<[^<>]+>/g
        var content = this.article.content.replace(reg, '')
        $("meta[name='description']").attr('content', `${content.substring(0, 100)}`)
      })
    },
    transArticleContent(content) {
      let titleIds = []
      //正则匹配<h1>~<h6>（即锚点）的html内容
      const titles = content.match(new RegExp('<h[1-6]([^>]+?|)>([^$]+?)</h[1-6]>', 'g'))
      //遍历<h1>~<h6>的html内容
      titles &&
        titles.forEach((item, index) => {
          let title = item.substr(item.indexOf('>') + 1, item.length - 4 - (item.indexOf('>') + 1) - 1)
          let level = item.substr(2, 1)
          //判断<h1>~<h6>的标签中是否有id，若无id则添加id
          if (item.search('id="') >= 0) {
            let id = item.match(new RegExp('(?<=id=")([^$]+?)(?=")', 'g'))
            if (id && id.length && title) titleIds.push({ id: id.pop(), name: title })
          } else {
            let id = 'title__h' + level + '__' + index
            //文章内容锚点处添加id
            content = content.replace(item, item.replace('<h' + level, '<h' + level + ' id="' + id + '" '))
            if (title) titleIds.push({ id: id, name: title })
          }
        })

      return { points: titleIds, content: content }
    },
    helpfulBtn(n) {
      this.feedbackForm.type = n
      this.ishelpful = true
      this.feedbackForm.problem = []
      this.feedbackForm.content = ''
      if ((this.isUnhelpful = true)) {
        this.isUnhelpful = false
      }
    },
    unhelpfulBtn(n) {
      this.feedbackForm.type = n
      this.isUnhelpful = true
      this.feedbackForm.content = ''
      if ((this.ishelpful = true)) {
        this.ishelpful = false
      }
    },
    checkedProblemsChange() {
      this.uncheckAll = false
    },
    checkedAllChange() {
      this.feedbackForm.problem = []
      if (this.uncheckAll == true) {
        this.feedbackForm.problem.push('以上都不是')
      }
    },
    cancelFormSubmit() {
      this.ishelpful = false
      this.isUnhelpful = false
      nextTick(() => {
        this.$refs.feedbackFormRef.resetFields()
      })
    },
    async helpfulSubmit() {
      const { data: res } = await this.$http.post('bot/feedback', {
        id: this.article.id,
        type: this.feedbackForm.type,
        problem: '',
        content: this.feedbackForm.content
      })
      this.isSubmit = false
    },
    UnhelpfulSubmit() {
      this.$refs.feedbackFormRef.validate(async valid => {
        if (!valid) return
        const data = await this.$http.post('bot/feedback', {
          id: this.article.id,
          type: this.feedbackForm.type,
          problem: this.feedbackForm.problem.join(','),
          content: this.feedbackForm.content
        })
        this.isSubmit = false
      })
    },
    // 图片放大缩小
    getImgPreview($event) {
      if ($event.target.innerHTML == '') {
        this.showViewer = true
        this.url = $event.target.src
      }
    },
    closeViewer() {
      this.showViewer = false
    },
    // 获取相关文章
    async getRelation() {
      const { data: res } = await this.$http.post('bot/related_questions', {
        id: this.id
      })
      this.relationList = res.data
    }
  }
}
</script>

<style scoped lang="less">
html {
  min-width: auto;
}
:deep(a) {
  color: #2b82ff;
}
:deep(h1) {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  opacity: 0.85;
}
:deep(h2) {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  opacity: 0.85;
}
:deep(p) {
  font-size: 16px;
  line-height: 1.5;
}
:deep(ul) {
  padding-left: 20px;
  li {
    list-style: disc;
  }
}
:deep(p) {
  img {
    display: block;
    width: 700px;
    margin: 0 auto;
    cursor: pointer;
  }
}
.btnClickColor {
  background: #2b82ff;
  border-color: #fff;
  color: #fff;
}
.article-content {
  padding-right: 20px;
  max-width: 1200px;
}
.time {
  display: inline-block;
  margin: 50px 0 20px;
  border-top: 1px solid #d9d9d9;
  padding-top: 30px;
}
.affix-box {
  width: 310px;
  .affix-pointer {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 310px;
    right: 30px;
    top: 0;
    background-color: #fff;
    .help-block {
      margin-bottom: 20px;
    }
    .feedback-box {
      .btns-box {
        display: flex;
        .el-button--info.is-plain:focus {
          background: #2b82ff;
          border-color: #fff;
          color: #fff;
        }
        .el-button {
          width: 50%;
          :deep(i[class^='el-icon-']) {
            font-size: 18px;
          }
        }
      }
      .el-checkbox {
        display: block;
      }
    }
    .submitTip {
      font-size: 14px;
      color: #000;
      i {
        font-size: 18px;
        color: #00f1a2;
        vertical-align: text-bottom;
      }
    }
    .anchor-box {
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid #dee0e3;
      .active a {
        color: #2b82ff;
      }
      :deep(.el-scrollbar__view) {
        border-left: 2px solid #d9d9d9;
        padding-left: 16px;
      }
      :deep(.el-row.active:before) {
        background-color: #2b82ff;
        content: ' ';
        display: block;
        height: 100%;
        left: -18px;
        top: -1px;
        position: absolute;
        width: 2px;
      }
    }
  }
}

.el-button--info.is-plain:hover {
  opacity: 0.65;
}
a[href] {
  word-break: break-all;
}
.relation {
  margin-bottom: 60px;
  h2 {
    font-size: 18px;
    color: #262626;
    opacity: 1;
  }
  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
    margin-bottom: 10px;
    a {
      font-size: 16px;
      color: #2b82ff;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
@media screen and (max-width: 768px) {
  .articleCon {
    flex-wrap: wrap;
  }
  .article-content {
    padding-right: 0;
    text-align: justify;
  }
  .time {
    margin: 30px 0 15px;
  }
  .relation {
    margin-bottom: 30px;
    li {
      a {
        font-size: 14px;
      }
    }
  }
  #title {
    font-size: 22px !important;
  }
  :deep(h1) {
    font-size: 18px;
  }
  :deep(h2) {
    font-size: 16px;
  }
  :deep(p),
  :deep(ul li) {
    font-size: 14px;
  }
  .anchor-box {
    display: none;
  }
}
</style>
