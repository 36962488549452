<template>
  <el-container class="home-container">
    <el-main>
      <!-- 路由占位符 -->
      <router-view></router-view>
    </el-main>
    <el-footer>
      <el-link
        :underline="false"
        href="https://beian.miit.gov.cn"
        target="_blank"
        style="width: 100%; text-align: center"
      >
        <p class="Text PCCopyright">
          杭州益刻网络科技有限公司版权所有Copyright © 2020
          <span><img src="@/assets/officeIcon.png" alt="" /></span>浙ICP备20011628号-1
        </p>
        <p class="Text MCopyright" style="display: none">
          杭州益刻网络科技有限公司版权所有Copyright © 2020<br />
          <span><img src="@/assets/officeIcon.png" alt="" /></span>浙ICP备20011628号-1
        </p>
      </el-link>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'ArticleHome',
  data() {
    return {
      userInfo:'',
           token: '',
      menus: [],
    }
  },
  mounted() {  
    this.userInfo = window.localStorage.getItem('userInfo')
     if (this.userInfo) {
      if (this.userInfo !== 'undefined') {
        this.userInfo = JSON.parse(this.userInfo)
        this.token = this.userInfo.token
      } else {
        this.token = ''
      }
    }
    this.getMenus()
  },
  methods: {
    async getMenus() {
      if (this.$route.name == 'help') {
        document.querySelector('html').style.minWidth = 'auto'
        document.querySelector('body').style.minWidth = 'auto'
        document.querySelector('#app').style.minWidth = 'auto'
        document.querySelector('#app').firstChild.style.minWidth = 'auto'
      }
      const { data: res } = await this.$http.post('bot/menu', {
        token:this.token,
      })
      this.menus = res.data
    },
  },
}
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}
html {
  min-width: auto;
}
.home-container {
  height: 100%;
}
.el-main {
  padding: 0 !important;
}

.el-footer {
  background-color: #f4f4f4;
  padding: 8px 0;
  height: auto !important;
  z-index: 1;
  p img {
    vertical-align: sub;
  }
}

@media screen and (max-width: 768px) {
  .PCCopyright {
    display: none;
  }
  .MCopyright {
    display: block !important;
    font-size: 12px;
  }
}
</style>
